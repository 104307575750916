import React from "react";
import Skills from "./Skills.js";
import Tilt from "react-parallax-tilt";
import Avatar from "../images/fidh2.png";
import Lottie from "lottie-react";
import Coder from "../LottieFiles/coder.json";

const About = () => {
  return (
    <>
      <div className="AboutPage">
        <div className="AboutText">
          <h1 className="AboutTextHeading">
            Get to <b>know</b> me!
          </h1>
          <p>
            Hi, my name is <b>Mochamad Hafidh Dwyanto</b> and I am from Bandung,
            Indonesia. I'm a <b>Front-End web and mobile developer</b> and a
            final year college student pursuing <b>Politeknik Negeri Bandung</b>
            . <br />
            <br />I have done an internship as a <b>software developer</b> at
            GoalFi which is a fintech startup. I love to create original
            projects with beautiful designs, you can check out some of my work
            in the projects section.
            <br />
            <br />I am <b>open</b> to new collaborations or work where I can
            contribute and grow. Feel free to connect with me, links are in the
            footer.
            <br />
            Apart from coding I love to do photography, you can check out some
            of my shots here{" "}
            <a
              href="https://www.instagram.com/devansh_sahni_/?hl=en"
              target="_blank"
            >
              Instagram.
            </a>
          </p>
        </div>

        <div>
          <Tilt>
            {/* <Lottie
              className="illustration"
              animationData={Coder}
              loop={true}
            /> */}
            <img className="Avatar" src={Avatar} alt="" />
          </Tilt>
        </div>
      </div>

      <h1 className="SkillsHeading">Professional Skillset</h1>
      <div className="skills">
        <Skills skill="HTML" />
        <Skills skill="CSS" />
        <Skills skill="Javascript" />
        <Skills skill="C++" />
        <Skills skill="Bootstrap" />
        <Skills skill="Npm" />
        <Skills skill="React" />
        <Skills skill="Vuejs" />
        <Skills skill="Nodejs" />
        <Skills skill="Git" />
        <Skills skill="Github" />
        <Skills skill="Postman" />
        <Skills skill="Figma" />
      </div>
    </>
  );
};

export default About;
