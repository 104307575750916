import React from "react";
import Lottie from "lottie-react";
import SpaceBoy from "../LottieFiles/SpaceBoy.json";
import Typed from "./Typed.js";
import Tilt from "react-parallax-tilt";
import Avatar from "../images/fidh.png";
import { CiCoffeeCup } from "react-icons/ci";

const Home = () => {
  return (
    <div>
      <div className="HomePage">
        <div className="HomeText">
          <h1>Hi There!</h1>
          <h1>
            I'M <b>Mochamad Hafidh Dwyanto</b>
          </h1>
          <Typed />
        </div>

        <Lottie className="illustration" animationData={SpaceBoy} loop={true} />
      </div>

      <div className="AboutPage">
        <div className="AboutText">
          <h1 className="AboutTextHeading">
            <b>introduction</b>
          </h1>
          <p>
            Tucked within each project are stories that strengthen the
            foundation of my craft. Welcome to the pages of creativity and
            dedication that I immortalized in this portfolio.
            <br />
            <br />
            With expertise in creating websites tailored to user needs, I rely
            on a strong foundation in <b>HTML</b>, <b>CSS</b>, and{" "}
            <b>JavaScript</b>.
            <br />
            These are the basics of programming that I apply with full
            <br />
            Also, I love <b>coffee</b>{" "}
            <CiCoffeeCup style={{ scale: "1.5", rotate: "15deg" }} />
          </p>
        </div>
        <Tilt>
          <img className="Avatar" src={Avatar} alt="" />
        </Tilt>
      </div>
    </div>
  );
};

export default Home;
